import { Component } from '@angular/core';
import { CountryFeatureService, CurrentUserService } from '@security/user';
import { map, Observable } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { MatIcon } from '@angular/material/icon';
import { RouterLinkActive, RouterLink } from '@angular/router';
import { MatAnchor } from '@angular/material/button';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'ic-main-navigation',
  templateUrl: './main-navigation.component.html',
  standalone: true,
  imports: [MatAnchor, RouterLinkActive, RouterLink, MatIcon, AsyncPipe, TranslateModule]
})
export class MainNavigationComponent {
  public isAuthorized$: Observable<boolean>;
  public consumptionEnabled$: Promise<boolean>;
  public documentsEnabled$: Promise<boolean>;

  public constructor(
    private currentUser: CurrentUserService,
    private countryFeature: CountryFeatureService
  ) {
    this.subscribeToCurrentUserFeatures();
    this.subscribeToCountryFeatures();
  }

  private subscribeToCurrentUserFeatures() {
    this.isAuthorized$ = this.currentUser.ensureUser$.pipe(
      map(user => !!user)
    );
  }

  private subscribeToCountryFeatures() {
    this.consumptionEnabled$ = this.countryFeature.isConsumptionEnabled();
    this.documentsEnabled$ = this.countryFeature.isDocumentEnabled();
  }
}
