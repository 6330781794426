import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { DateFormats } from '@shared/common';

@Injectable({
  providedIn: 'root'
})
export class DateConverterService {
  public convertDateStringToDateObject(dateString: string) {
    return DateTime.fromFormat(dateString, DateFormats.dateReverse);
  }

  public convertDateStringToDateObjectFirstDayOfMonth(dateString: string) {
    return DateTime.fromFormat(dateString, DateFormats.dateReverse)
      .startOf('month')
      .startOf('day');
  }

  public ensureDateTimeInstance(date: DateTime | string): DateTime {
    return date instanceof DateTime
      ? date
      : DateTime.fromFormat(date, DateFormats.dateReverse);
  }

  // public convertDateStringToDateObjectLastDayOfMonth(dateString: string) {
  //   return DateTime.fromFormat(dateString, DateFormats.date)
  //     .endOf('month')
  //     .startOf('day');
  // }
}
