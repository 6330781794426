import { FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { DateTime } from 'luxon';

export const DATE_RANGE_VALIDATOR_NAME = 'validDateRange';

// eslint-disable-next-line max-len
export const validateDateRange = (firstControlName: string, secondControlName: string): ValidatorFn => (formGroup: FormGroup): ValidationErrors | null => {
  const value = formGroup.value;

  if (!value[firstControlName] || !value[secondControlName]) { return null; }

  const moveIn: DateTime = value[firstControlName];
  const moveOut: DateTime = value[secondControlName];

  if (moveIn && !moveIn.isValid || moveOut && !moveOut.isValid) { return null; }

  if (moveIn < moveOut) { return null; }

  return { [DATE_RANGE_VALIDATOR_NAME]: true };
};
