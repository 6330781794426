<!-- NOTE: markup implementation taken from fl-ui-phoenix-shell -->
<div class="flex items-center bg-primary-50/35 px-8 py-4">
  @for (conf of configuration; track conf; let isLast = $last) {
    <div class="text-[15px] uppercase">
      @if (configuration.length === 1) {
        <span class="text-primary-500">{{ conf.label | translate }}</span>
      } @else {
        @if (!isLast) {
          <span class="cursor-pointer text-gray-500 hover:text-gray-600" (click)="handleNavigation(conf)">{{
            conf.label | translate
          }}</span>
          <span class="mx-[3px] text-gray-500">/</span>
        } @else {
          <span class="text-primary-500"> {{ conf.label | translate }}</span>
        }
      }
    </div>
  }
</div>
