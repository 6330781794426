import { Pipe, PipeTransform } from '@angular/core';
import { LanguageLocale } from '@shared/localization/language.model';
import { LanguageSelectionService } from '@shared/localization';
import { DateTime } from 'luxon';

@Pipe({
  name: 'localizedDate',
  standalone: true
})
export class LocalizedDatePipe implements PipeTransform {
  public constructor(
    private languageSelection: LanguageSelectionService
  ) {
  }

  public transform(value: DateTime, locale?: LanguageLocale, format?: string): unknown {
    if (!value) {
      return null;
    }

    if (!(value instanceof DateTime)) {
      throw new Error('DateFormatPipe: only DateTime value type is supported.');
    }

    if (!value.isValid) {
      return null;
    }

    const languageCode = locale?.lang || this.languageSelection.localeSnapshot.lang;

    if (!format) {
      return value.setLocale(languageCode).toLocaleString({ ...DateTime.DATE_SHORT, day: '2-digit', month: '2-digit' });
    }

    return value.setLocale(languageCode).toFormat(format);
  }
}
