import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PasswordStrengthService {
  public validate(password: string) {
    return this.checkIf(password).meetsAllConditions(
      this.hasProperLength,
      this.atLeastOneLowercaseLetter,
      this.atLeastOneUppercaseLetter,
      this.atLeastOneNumber,
      this.atLeastOneSpecialChar.bind(this),
    );
  }

  private checkIf(password: string) {
    return {
      meetsAllConditions: (...delegates: ((value: string) => boolean)[]) =>
        delegates.every((delegate) => delegate(password)),
    };
  }

  private atLeastOneUppercaseLetter(password: string) {
    return /(?=.*[A-Z])/.test(password);
  }

  private atLeastOneLowercaseLetter(password: string) {
    return /(?=.*[a-z])/.test(password);
  }

  private atLeastOneNumber(password: string) {
    return /[0-9]+/.test(password);
  }

  private atLeastOneSpecialChar(password: string) {
    if (password) {
      const passwordArray = password.split('');
      let numDigitOrLetter = 0;
      for (const character of passwordArray) {
        if (this.atLeastOneLowercaseLetter(character) || this.atLeastOneUppercaseLetter(character) || this.atLeastOneNumber(character)) {
          numDigitOrLetter++;
        }
      }

      return password.length > numDigitOrLetter;
    } else {
      return false;
    }
  }

  private hasProperLength(password: string) {
    const minLength = 12;
    const maxLength = 64;

    return minLength <= password.length && password.length <= maxLength;
  }
}
