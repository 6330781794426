@if (locale$ | async; as locale) {
  <button mat-icon-button [matMenuTriggerFor]="languageSelector">
    <img [src]="'assets/icons/flags/' + locale.lang + '.png'" />
  </button>
  <mat-menu #languageSelector="matMenu" class="gt-xs:pl-3 flex-auto">
    @for (localeString of allowedLocaleStrings; track localeString) {
      <button
        mat-menu-item
        [ngClass]="locale.localeString === localeString ? 'bg-primary-500/5' : ''"
        (click)="onChangeLanguage(localeString)"
      >
        <img class="mr-2 w-6" src="{{ 'assets/icons/flags/' + languageFromLocale(localeString) + '.png' }}" />
        <div [ngClass]="locale.localeString === localeString ? 'text-primary' : ''">
          {{ "LANG_" + localeString.toUpperCase() | translate }}
        </div>
      </button>
    }
  </mat-menu>
}

@if (isAuthorized$ | async) {
  <button mat-icon-button [matMenuTriggerFor]="accountMenu">
    <mat-icon class="icon-size-6">account_circle</mat-icon>
  </button>
}

<mat-menu #accountMenu="matMenu" class="gt-xs:pl-3 flex-auto">
  @if (isChangePasswordEnabled$ | async) {
    <button mat-menu-item [routerLink]="['/password']">
      <mat-icon class="icon-size-6">lock</mat-icon>
      {{ "CHANGE_PASSWORD" | translate }}
    </button>
  }

  @if (isChangeEmailEnabled$ | async) {
    <button mat-menu-item [routerLink]="['/email']">
      <mat-icon class="icon-size-6">mail</mat-icon>
      {{ "CHANGE_EMAIL" | translate }}
    </button>

    <button mat-menu-item [routerLink]="['/user/settings']">
      <mat-icon class="icon-size-6">settings</mat-icon>
      {{ "SETTINGS" | translate }}
    </button>
  }

  <button mat-menu-item [routerLink]="['/user/settings/mfa']">
    <mat-icon class="icon-size-6">admin_panel_settings</mat-icon>
    {{ "SETTINGS_MFA.MENU_TITLE" | translate }}
  </button>

  <button mat-menu-item [routerLink]="['/logout']">
    <mat-icon class="text-warn-500 icon-size-6">logout</mat-icon>
    <span class="text-warn-500">
      {{ "LOGOUT" | translate }}
    </span>
  </button>
</mat-menu>
