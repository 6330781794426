import { Injectable } from '@angular/core';
// eslint-disable-next-line max-len
import { ChartData, ConsumptionDataPoints, ConsumptionUnit, DeviceDetails, DeviceMeteringPoint, Interval, SeriesData, SeriesDataConversionPayload } from '../models';
import { DateConverterService } from '@shared/consumption';
import { ConsumptionChartDataService } from './consumption-chart-data.service';
import { BehaviorSubject, Observable, catchError, forkJoin, map, mergeMap, of, shareReplay } from 'rxjs';
import { Notification, NotificationService } from '@shared/notifications';
import { HttpErrorResponse } from '@angular/common/http';
import { GetConsumptionPayload, ConsumptionApiService } from './consumption-api.service';
import { DateTime } from 'luxon';

@Injectable({
  providedIn: 'root'
})
export class ConsumptionService {
  public dataPoints$: Observable<{ readings: ConsumptionDataPoints; deviceDetails: DeviceDetails }>;
  private dataPointsBus = new BehaviorSubject<GetConsumptionPayload>(undefined);

  public constructor(
    private dateConverter: DateConverterService,
    private consumptionChartData: ConsumptionChartDataService,
    private resource: ConsumptionApiService,
    private notifications: NotificationService,
  ) {
    this.subscribeToDataPoints();
  }

  public refreshDataPoints(payload: GetConsumptionPayload) {
    return this.dataPointsBus.next(payload);
  }

  public convertDataPointsToSeriesData<TValue>(
    dataPoints: SeriesDataConversionPayload<TValue>,
    dailyMode?: boolean
  ): SeriesData<TValue>[] {
    return Object.keys(dataPoints).map(dateKey => ({
      date: dailyMode
        ? this.dateConverter.convertDateStringToDateObject(dateKey)
        : this.dateConverter.convertDateStringToDateObjectFirstDayOfMonth(dateKey),
      value: dataPoints[dateKey]
    }));
  }

  public convertDataPointsToDailySeriesData(dataPoints: SeriesData<number>[], monthlyMode?: boolean): SeriesData<number>[] {
    return dataPoints.map(point => ({
      date: monthlyMode
        ? this.dateConverter.ensureDateTimeInstance(point.date).startOf('month')
        : this.dateConverter.ensureDateTimeInstance(point.date),
      valueType: point.valueType,
      value: point.value
    }));
  }

  public switchChartDataUnit(chartData: ChartData, toGJ: boolean) {
    const CONVERT_FACTOR_KWH_TO_GJ = 0.0036101083;

    if (toGJ) {
      return this.consumptionChartData.convertChartData(
        chartData,
        ConsumptionUnit.GIGA_JOULE,
        CONVERT_FACTOR_KWH_TO_GJ
      );
    } else {
      return this.consumptionChartData.convertChartData(
        chartData,
        ConsumptionUnit.KILO_WATT_HOURS,
        1 / CONVERT_FACTOR_KWH_TO_GJ
      );
    }
  }

  public extractInterval<TValue>(dataPoints: SeriesData<TValue>[], startDate: DateTime, endDate: DateTime): SeriesData<TValue>[] {
    if (!dataPoints) {
      return null;
    }
    const items: SeriesData<TValue>[] = [];

    dataPoints.forEach((point) => {
      if (point.date >= startDate && point.date <= endDate) {
        items.push(point);
      }
    });

    return items;
  }

  public calculateTotal(dataPoints: SeriesData<number>[]) {
    if (!dataPoints) {
      return 0;
    }
    let total = 0;

    dataPoints.forEach((point) => {
      total += point.value;
    });

    return total;
  }

  public buildIntervalsForDataPointRange(dataPoints: SeriesData<number>[]) {
    let upperBound = 5;

    if (dataPoints.length === 0) {
      return [];
    }

    if (dataPoints.length <= 14) {
      upperBound = 1;
    } else if (dataPoints.length <= 30) {
      upperBound = 2;
    } else if (dataPoints.length <= 90) {
      upperBound = 3;
    } else if (dataPoints.length <= 180) {
      upperBound = 4;
    }

    return Object.values(Interval).slice(0, upperBound);
  }

  public getStartDateForInterval(interval: Interval): DateTime {
    // Intervals are actually not quite what they say, instead of 1 month, it uses 30 days, regardless of the amount
    // of days per month. Since it was in iC 1.0 like that the behavior was ported, but from a user perspective it
    // does not make much sense
    switch (interval) {
      case Interval.TWO_WEEKS: {
        return DateTime.now().minus({ day: 14 }).startOf('day');
      }
      case Interval.ONE_MONTH: {
        return DateTime.now().minus({ day: 30 }).startOf('day');
      }
      case Interval.THREE_MONTHS: {
        return DateTime.now().minus({ day: 90 }).startOf('day');
      }
      case Interval.SIX_MONTHS: {
        return DateTime.now().minus({ day: 180 }).startOf('day');
      }
      case Interval.TWELVE_MONTHS: {
        return DateTime.now().minus({ day: 365 }).startOf('day');
      }
      default:
        throw new Error('Invalid interval');
    }
  }

  private subscribeToDataPoints() {
    this.dataPoints$ = this.dataPointsBus
      .pipe(
        mergeMap((payload: GetConsumptionPayload) => forkJoin({
          readings: this.resource.getDataPoints(payload)
            .pipe(
              map((readings) => ({
                ...readings,
                values: this.convertDataPointsToDailySeriesData(readings.values, false),
              }))
            ),
          deviceDetails: this.resource.getDeviceDetails(payload)
            .pipe(
              map((deviceDetails) => ({
                ...deviceDetails,
                meteringPoints: this.stripLeadingZerosFromSerialNumber(deviceDetails.meteringPoints),
              }))
            ),
        })
          .pipe(
            // Error handling needs to be done in the inner observable to not destroy the outer one on errors
            catchError((e) => this.handleDataPointsError(e)),
          )),
        shareReplay(1),
      );
  }

  private handleDataPointsError(e: HttpErrorResponse) {
    const MISSING_DEVICE_STATUS = 417;

    if (e.error.errorMessage.indexOf('No consumption values') > -1
      || e.error.errorMessage.indexOf('Device data series not available') > -1) {
      this.notifications.show(
        Notification.inline().asWarning({
          messageKey: 'CONSUMPTION_NO_DAILY_CONSUMPTIONS'
        }),
      );
    } else if (e.status === MISSING_DEVICE_STATUS) {
      // Silent error, do not show alert.
      // Order is important here: "No consumption values" error also returns HTTP 417
    } else {
      this.notifications.show(
        Notification.toast().asError({
          messageKey: 'CONSUMPTION_LOAD_ERROR',
        }),
      );
    }

    return of({ readings: { values: [] }, deviceDetails: {} } as any);
  }

  private stripLeadingZerosFromSerialNumber(meteringPoints: DeviceMeteringPoint[]) {
    return meteringPoints.map((mp) => ({
      ...mp,
      serialNumber: mp.serialNumber.replace(/^0+/, ''),
    }));
  }
}
