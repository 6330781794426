import { Injectable } from '@angular/core';
import { DateConverterService } from '@shared/consumption';
import { DateRange } from '@shared/common';
import { AvailableMonth, RoomsConsumptionValue } from '../models';
import { DateTime } from 'luxon';

const ALLOWED_RANGE_MAX_YEARS = 3;
const DEFAULT_RANGE_MAX_YEARS = 1;

@Injectable({
  providedIn: 'root'
})
export class RoomsConsumptionPieDateService {
  public constructor(
    private dateConverter: DateConverterService
  ) { }

  public getAvailableDataTimeRange(roomList: RoomsConsumptionValue[], currentDate: DateTime) {
    const dateList = this.createDateList(roomList);

    const minDateInData = DateTime.min(...dateList).startOf('month');
    const maxDateInData = DateTime.max(...dateList).endOf('month');

    const allowedRangeEnd = currentDate.endOf('month');
    const allowedRangeStart = allowedRangeEnd
      .minus({ year: ALLOWED_RANGE_MAX_YEARS })
      .startOf('month');

    const availableDataTimeRangeStart = DateTime.max(allowedRangeStart, minDateInData);

    return { startDate: availableDataTimeRangeStart, endDate: maxDateInData };
  }

  public initialDateRange(availableDataTimeRange: DateRange<DateTime>, desiredDates: DateRange<DateTime>) {
    const initialStartDate = this.getDefaultDataTimeRange(availableDataTimeRange.startDate, availableDataTimeRange.endDate);

    return {
      startDate: this.resolveDate(desiredDates?.startDate, initialStartDate.startDate, availableDataTimeRange),
      endDate: this.resolveDate(desiredDates?.endDate, initialStartDate.endDate, availableDataTimeRange)
    };
  }

  public generateAvailableDataTimeRangeMonths(range: DateRange<DateTime>): AvailableMonth[] {
    if (!range) { return []; }

    const startDate = range.startDate;
    let currentEndDate = range.endDate;

    const availableMonths: AvailableMonth[] = [];

    while (startDate <= currentEndDate) {
      availableMonths.push({
        month: currentEndDate.month,
        year: currentEndDate.year
      });

      currentEndDate = currentEndDate.minus({ month: 1 }).endOf('month');
    }

    return availableMonths;
  }

  private resolveDate(expected: DateTime, defaultValue: DateTime, availableRange: DateRange<DateTime>) {
    if (expected) {
      const startOfMonthWithinRange = expected.startOf('month');

      return (startOfMonthWithinRange >= availableRange.startDate && startOfMonthWithinRange < availableRange.endDate)
        ? expected
        : defaultValue;
    }

    return defaultValue;
  }

  private createDateList(roomsWithValues: RoomsConsumptionValue[]) {
    return roomsWithValues
      .map(room => room.values)
      .reduce((a, b) => a.concat(b))
      .map(value => this.dateConverter.ensureDateTimeInstance(value.date));
  }

  private getDefaultDataTimeRange(startDate: DateTime, endDate: DateTime) {
    const defaultRangeEnd = endDate.endOf('month');
    const defaultRangeStartMin = defaultRangeEnd
      .minus({ year: DEFAULT_RANGE_MAX_YEARS })
      .plus({ month: 1 })
      .startOf('month');
    const defaultRangeStart = DateTime.max(startDate, defaultRangeStartMin);

    return { startDate: defaultRangeStart, endDate: defaultRangeEnd };
  }
}
