import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'ic-notice-de',
  templateUrl: './notice_de.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class NoticeDEComponent {}

@Component({
  selector: 'ic-notice-en',
  templateUrl: './notice_en.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class NoticeENComponent {}

@Component({
  selector: 'ic-notice-hr',
  templateUrl: './notice_hr.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class NoticeHRComponent {}

@Component({
  selector: 'ic-notice-it',
  templateUrl: './notice_it.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class NoticeITComponent {}

@Component({
  selector: 'ic-notice-pl',
  templateUrl: './notice_pl.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class NoticePLComponent {}

@Component({
  selector: 'ic-notice-ro',
  templateUrl: './notice_ro.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class NoticeROComponent {}
