import { Injectable } from '@angular/core';
import { combineLatest, firstValueFrom, from } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserFeature } from '../models/user.model';
import { CurrentUserService } from './current-user.service';
import { ConsumptionType } from "@shared/consumption";

// TODO: refactoring needed
@Injectable({
  providedIn: 'root'
})
export class CountryFeatureService {
  private GJ_UNIT_CONVERSION_FEATURE_NAME = 'GJ_UNIT_CONVERSION';
  private CHANGE_EMAIL_FEATURE_NAME = 'CHANGE_EMAIL';
  private CHANGE_PASSWORD_FEATURE_NAME = 'CHANGE_PASSWORD';
  private YEARS_FEATURE_NAME = 'CONSUMPTION_YEARS';
  private MONTHS_FEATURE_NAME = 'CONSUMPTION_MONTHS';
  private PERIODS_FEATURE_NAME = 'CONSUMPTION_PERIODS';
  private WEEKS_FEATURE_NAME = 'CONSUMPTION_WEEKS';
  private DAYS_FEATURE_NAME = 'CONSUMPTION_DAYS';
  private ROOMS_FEATURE_NAME = 'ROOMS';
  private DOCUMENT_FEATURE_NAME = 'DOCUMENTS';
  private ACCOUNT_FEATURE_NAME = 'ACCOUNT';

  public constructor(
    private currentUser: CurrentUserService
  ) { }

  public get currentUser$() {
    return this.currentUser.ensureUser$;
  }

  public isGigaJouleUnitConversionEnabled() {
    return this.isFeatureEnabled(this.GJ_UNIT_CONVERSION_FEATURE_NAME);
  }

  public isChangeEmailEnabled() {
    return this.isFeatureEnabled(this.CHANGE_EMAIL_FEATURE_NAME);
  }

  public isChangePasswordEnabled() {
    return this.isFeatureEnabled(this.CHANGE_PASSWORD_FEATURE_NAME);
  }

  public isYearsEnabled() {
    return this.isFeatureEnabled(this.YEARS_FEATURE_NAME);
  }

  public isMonthsEnabled() {
    return this.isFeatureEnabled(this.MONTHS_FEATURE_NAME);
  }

  public isPeriodsEnabled() {
    return this.isFeatureEnabled(this.PERIODS_FEATURE_NAME);
  }

  public isWeeksEnabled() {
    return this.isFeatureEnabled(this.WEEKS_FEATURE_NAME);
  }

  public isDaysEnabled() {
    return this.isFeatureEnabled(this.DAYS_FEATURE_NAME);
  }

  public isRoomsEnabled() {
    return this.isFeatureEnabled(this.ROOMS_FEATURE_NAME);
  }

  public isDocumentEnabled() {
    return this.isFeatureEnabled(this.DOCUMENT_FEATURE_NAME);
  }

  public needsExplanationForConsumptions(consumptionType: ConsumptionType) {
    const countries = [ 'RO' ];
    const consumptionTypes = [ ConsumptionType.HEAT, ConsumptionType.HEATING ];

    return this.currentUser$.pipe(
      map(currentUser => countries.includes(currentUser.countrySetting.country) && consumptionTypes.includes(consumptionType))
    );
  }

  public moreThanOneOption():Promise<boolean>{
    return Promise.all([
      this.isConsumptionEnabled(),
      this.isDocumentEnabled(),
    ]).then(value => {
       return value.filter(d => d).length > 1;
    });
  }

  public isConsumptionEnabled() {
    const result = combineLatest([
      from(this.isYearsEnabled()),
      from(this.isMonthsEnabled()),
      from(this.isWeeksEnabled()),
      from(this.isDaysEnabled()),
      from(this.isRoomsEnabled())
    ])
      .pipe(
        map(res => {
          const [isYearsEnabled, isMonthsEnabled, isWeeksEnabled, isDaysEnabled, isRoomsEnabled] = res;
          return isYearsEnabled || isMonthsEnabled || isWeeksEnabled || isDaysEnabled || isRoomsEnabled;
        })
      );

    return firstValueFrom(result);
  }

  private async isFeatureEnabled(featureName: string) { // TODO should rather be Observable
    const currentUser = await firstValueFrom(this.currentUser$, { defaultValue: null });

    const merge = (isUserEnabled: boolean, isCountryEnabled: boolean) => isCountryEnabled === false
      ? false
      : isUserEnabled;

    const defined = (variable) => variable !== undefined;

    const featureEnabled = (featureNameToFind, features: UserFeature[]) => {
      const feature = features.find(aFeature => aFeature.featureName === featureNameToFind);

      return feature?.enabled;
    };
    const userFeatureEnabled = (featureNameToTest) =>
      featureEnabled(featureNameToTest, currentUser.features);
    const countryFeatureEnabled = (featureNameToTest) =>
      featureEnabled(featureNameToTest, currentUser.countrySetting.features);

    const noFeaturesDefined = () => {
      const noUserFeatures = !currentUser?.features?.length;
      const noCountryFeatures = !currentUser?.countrySetting?.features?.length;

      return noUserFeatures || noCountryFeatures;
    };


    if (noFeaturesDefined()) {
      return false;
    }

    const countryEnabled = countryFeatureEnabled(featureName);
    const userEnabled = userFeatureEnabled(featureName);

    return defined(userEnabled)
      ? merge(userEnabled, countryEnabled)
      : countryEnabled;
  }
}
