import { Injectable } from '@angular/core';
import { BreadcrumbConfiguration } from '@shared/layout/models';
import {
    BehaviorSubject
} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService {

  public breadcrumbs$ = new BehaviorSubject<BreadcrumbConfiguration>(null);

  public constructor() {}

  public change(config:BreadcrumbConfiguration){
    this.breadcrumbs$.next(config);
  }

  public hide(){
    this.breadcrumbs$.next(null);
  }
}
