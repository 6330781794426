import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const DATE_VALIDATOR_NAME = 'validDate';

export const validateDate = (): ValidatorFn => (control: AbstractControl): ValidationErrors | null => {
  if (!control.value) { return null; }

  const isValid = !control.value || control.value.isValid;

  return isValid
    ? null
    : { [DATE_VALIDATOR_NAME]: true };
};
