import { ChangeDetectionStrategy, Component, EventEmitter, input, Output, signal } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { TranslateModule } from '@ngx-translate/core';
import { CountryDetails } from '@shared/localization';

@Component({
  selector: 'ic-country-selector',
  template: `
		<div>
        <div class="flex flex-row items-center gap-3 mb-6">
            <div><span class="font-semibold">{{ 'TERMS_OF_USE.SELECT_COUNTRY' | translate }}</span></div>
            <div>
                <button mat-stroked-button [matMenuTriggerFor]="countrySelect">
                  @if(selectedCountry()){
                    {{ 'COUNTRIES.' + selectedCountry().countryCode | translate }}
                  }@else {
                    {{'TERMS_OF_USE.COUNTRY' | translate }}
                  }
                </button>
            </div>
        </div>
        <mat-menu #countrySelect="matMenu" class="flex-auto gt-xs:pl-3">
          @for (country of countries(); track country) {
             <button mat-menu-item value="country" (click)="onSelected(country)">{{ 'COUNTRIES.' + country.countryCode | translate }}</button>
          }
        </mat-menu>
		</div>
	`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatIcon, MatMenu, MatMenuItem, MatMenuTrigger, MatButton, TranslateModule]
})
export class CountrySelectorComponent {

  public countries = input<CountryDetails[]>([]);
  public selectedCountry = signal<CountryDetails>(undefined);

  @Output()
  public selected = new EventEmitter<string>();

  public onSelected(country: CountryDetails) {
    this.selectedCountry.set(country);
    this.selected.next(country.countryCode);
  }

}
