export enum TimeUnit {
  MONTH = 'MONTH',
  DAY = 'DAY',
};

export const DateFormats = {
  year: 'yyyy',
  yearMonth: 'yyyy-LL',
  date: 'dd-LL-yyyy',
  dateSeparatedByDots: 'dd.LL.yyyy',
  dateReverseDayMonth: 'LL-dd-yyyy',
  dateReverse: 'yyyy-LL-dd',
  dateLong: 'd MMM yyyy',
  monthName: 'LLLL',
  monthNameWithYear: 'MMMM yyyy',
  monthNameShort: 'LLL',
  monthNameShortWithYear: 'MMM yyyy',
  weekNameShort: 'EEEEEE',
  dayOfMonth: 'MMM d',
  dateWithTime: 'dd-LL-yyyy HH:mm:ss'
};

export interface DateRange<TDate> {
  startDate: TDate;
  endDate: TDate;
}
