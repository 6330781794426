import { ChangeDetectionStrategy, Component, signal, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { UserActivationFormComponent } from '@security/activation/components/user-activation-form/user-activation-form.component';
import { ErrorCodeService } from '@security/activation/services';
import { UserActivationApiService } from '@security/user';
import { DefaultLayoutComponent } from '@shared/layout/components/default-layout';
import { BreadcrumbConfiguration } from '@shared/layout/models';
import { Notification, NotificationLiveTime, NotificationService } from '@shared/notifications';

@Component({
  template: `
    <ic-default-layout [breadcrumb]="breadcrumb()">
      <div class="flex flex-col p-6 flex-1" >
        <div class="flex flex-col gap-4 max-w-2xl">
          @if(showActivationForm()){
            <h3>{{ "USER_ACTIVATION.WELCOME" | translate }}</h3>
            <p>
              <span [innerHTML]="'USER_ACTIVATION.TEXT' | translate"></span>
            </p>
          }
          <ic-user-activation-form #activationForm
                                   [hidden]="!showActivationForm()"
                                   (save)="onActivate($event)">
          </ic-user-activation-form>
        </div>
      </div>
    </ic-default-layout>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [UserActivationFormComponent, TranslateModule, DefaultLayoutComponent]
})
export class UserActivationComponent {

  @ViewChild('activationForm', { static: true })
  public activationForm: UserActivationFormComponent;
  public showActivationForm = signal<boolean>(true);
  public readonly breadcrumb = signal<BreadcrumbConfiguration>([
    { label: 'USER_ACTIVATION.TITLE' }
  ]);

  public constructor(
    private route: ActivatedRoute,
    private userActivationResource: UserActivationApiService,
    private errorCodes: ErrorCodeService,
    private notification: NotificationService
  ) {
  }

  public onActivate({ newPassword }: { newPassword: string }) {

    const token = this.route.snapshot.params.token;
    this.userActivationResource
      .userActivation({ newPassword, token })
      .subscribe({
        next: () => this.onActivated(),
        error: (response) => this.onActivateFailed(response)
      });

  }

  private onActivated() {

    this.activationForm.reset();
    this.showActivationForm.set(false);
    this.notification.show(
      Notification.inline().asSuccess({
        messageKey: 'USER_ACTIVATION.SUCCESS',
        showLink: true,
        linkUrl: '/',
        linkText: 'GOTO_LOGIN'
      })
    );

  }

  private onActivateFailed(response) {

    const messageKey = this.errorCodes.createErrorMessage(
      response,
      'USER_ACTIVATION',
      'ERROR_DEFAULT',
      true
    );
    this.notification.show(
      Notification.toast().asError({ messageKey, liveTime: NotificationLiveTime.LONG })
    );

  }

}
