import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class IstaColorPaletteService {
  private readonly colors: string[];

  public constructor() {
    this.colors = ['#0065E4','#458CDF','#01D3D3','#003C32','#13A45B','#76D2A4','#003372', '#4C3CB0','#75339D','#DA35F5','#64748B','#8CA7A3'];
  }

  public colorAsCss(index: number) {
    return this.colors[index % this.colors.length];
  }
}

