import { ChangeDetectionStrategy, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { FormKeys, FormKeys as NestedFormKeys, NewPasswordFieldsSectionComponent, PasswordStrengthService, ValidationKeys } from '@security/change-password';
import { debounceTime, Subscription } from 'rxjs';

interface FormValue {
  [NestedFormKeys.NEW_PASSWORD]: string;
  [NestedFormKeys.NEW_PASSWORD_CONFIRMATION]: string;
}

@Component({
    selector: 'ic-user-activation-form',
    template: `
        <form [formGroup]="formGroup" (ngSubmit)="onSubmit()" novalidate class="flex flex-col gap-4 max-w-lg">
          <div class="flex flex-col gap-4">
      			<ic-new-password-fields-section
              [includeTooltip]="true"
              class="flex flex-col gap-4 mt-2"
      				[controlHasErrorFn]="controlHasError.bind(this)">
      			</ic-new-password-fields-section>
            </div>
            <div class="flex flex-row gap-4">
                <button
                    mat-raised-button
                    color="primary"
                    type="submit"
                    class="w-full md:w-auto md:min-w-48"
                   [disabled]="formGroup.invalid"
                  >
                  <mat-icon>check</mat-icon>
                  {{ "USER_ACTIVATION.BUTTON" | translate }}
              </button>
            </div>

		</form>
	`,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [FormsModule, MatIcon, MatButton, ReactiveFormsModule, NewPasswordFieldsSectionComponent, TranslateModule]
})
export class UserActivationFormComponent implements OnInit, OnDestroy {

  @Output()
  public save = new EventEmitter<{ newPassword: string }>();

  @Output()
  public changed = new EventEmitter<void>();

  public formGroup: FormGroup;
  private subscriptions = new Subscription();

  public constructor(
    private formBuilder: FormBuilder,
    private passwordStrength: PasswordStrengthService
  ) {
    this.createForm();
  }

  public ngOnInit() {
    this.subscribeToFormChanges();
  }

  public ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  public onSubmit() {

    if (this.formGroup.invalid) { return; }
    const value: FormValue = this.formGroup.value;
    this.save.emit({
      newPassword: value[NestedFormKeys.NEW_PASSWORD]
    });

  }

  public controlHasError(controlName: FormKeys, validatorName?: ValidationKeys) {

    const formControl = this.formGroup.get(controlName);
    const isInvalid = !!formControl.errors
      && (!validatorName || !!formControl.errors[validatorName.toLowerCase()]);

    return formControl.invalid && isInvalid && formControl.touched;

  }

  public reset() {
    this.formGroup.reset({}, { emitEvent: false });
    this.formGroup.markAsPristine();
  }

  private createForm() {

    this.formGroup = this.formBuilder.group({
      [FormKeys.NEW_PASSWORD]: ['', [Validators.required, this.strongPassword.bind(this)]],
      [FormKeys.NEW_PASSWORD_CONFIRMATION]: ['', [Validators.required]]
    });

  }

  private strongPassword(control: AbstractControl) {

    if (!control.value) { return null; }
    return this.passwordStrength.validate(control.value)
      ? null
      : { [ValidationKeys.STRONG_PASSWORD]: true };

  }

  private subscribeToFormChanges() {

    this.subscriptions.add(this.formGroup.valueChanges
      .pipe(
        debounceTime(250)
      )
      .subscribe(() => this.changed.emit()));

  }
}
