<fuse-drawer
  [name]="'leftDrawer'"
  [opened]="expanded()"
  [mode]="(isSmallScreen$ | async) ? 'over' : 'side'"
  [transparentOverlay]="false"
  [@enterAnimation]
  class="ista-vertical-navigation"
  (openedChanged)="onDrawerToggled($event)"
>
  <div class="ista-sidenav">
    <a [routerLink]="['/welcome']" class="flex justify-center">
      <img
        [src]="brandLogo + '?rev=' + assetsRev"
        [fallbackSrc]="'/assets/img/ista_Logo_Connect_horizontal_OceanBlue_RGB.png?rev=' + assetsRev"
        alt="istaConnect"
        class="max-h-[80px] max-w-[222px]"
      />
    </a>

    <div class="px-4 pb-4 pt-1">
      <ic-main-navigation></ic-main-navigation>
    </div>
  </div>
</fuse-drawer>

<section>
  <mat-toolbar class="ista-ui-header md-whiteframe-z1">
    <button
      mat-icon-button
      class="mdc-icon-button mat-mdc-icon-button mat-unthemed mat-mdc-button-base ml-1"
      (click)="toggleDrawer()"
    >
      <mat-icon class="icon-size-6">menu</mat-icon>
    </button>
    @if (!expanded()) {
      <a [routerLink]="['/welcome']" [@enterAnimation] class="flex justify-center">
        <img
          [src]="brandLogo + '?rev=' + assetsRev"
          [fallbackSrc]="'/assets/img/ista_Logo_Connect_horizontal_OceanBlue_RGB.png?rev=' + assetsRev"
          alt="istaConnect"
          class="max-h-[80px] max-w-[222px]"
        />
      </a>
    }
    <div class="w-full">
      <ic-top-navigation class="flex flex-row justify-end p-4"></ic-top-navigation>
    </div>
  </mat-toolbar>

  @if (breadcrumb$ | async; as breadcrumb) {
    <section>
      <ic-breadcrumb [configuration]="breadcrumb"> </ic-breadcrumb>
    </section>
  }
  <main>
    <ic-inline-notification></ic-inline-notification>
    <ng-content></ng-content>
    <router-outlet></router-outlet>
  </main>

  <footer>
    <ic-app-footer [isBranded]="isBranded"></ic-app-footer>
  </footer>

  <div class="flex-cookies flex-sticky-cookies">
    <ic-accept-cookies class="accept-cookies" language="{{ (locale$ | async)?.lang }}"> </ic-accept-cookies>
  </div>
</section>

<ic-spinner></ic-spinner>
<ic-toasts></ic-toasts>
