import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { filter, map, Observable, Subscription, tap } from 'rxjs';
import { AlertBoxComponent, AlertInfo } from './alert-box.component';
import { ClearInlineNotifications, InlineNotification, NotificationType } from './notification';
import { NotificationService } from './notification.service';

@Component({
  selector: 'ic-inline-notification',
  template: ` <ic-alert-box [fade]="true" [alertInfo]="data$ | async"> </ic-alert-box> `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [AlertBoxComponent, AsyncPipe],
})
export class InlineNotificationComponent implements OnDestroy {
  @Input()
  public identifier: string;

  public data$: Observable<AlertInfo>;
  private subscriptions: Subscription = new Subscription();

  public constructor(
    private notification: NotificationService,
    private changeDetector: ChangeDetectorRef,
    private router: Router,
  ) {
    this.subscribeToNotifications();
    this.subscribeToRouterEvents();
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private subscribeToNotifications() {
    this.data$ = this.notification.notifications$.pipe(
      filter((n) => n instanceof InlineNotification || n instanceof ClearInlineNotifications),
      filter((n: InlineNotification) => !n.identifier || this.identifier === n.identifier),
      map((notification: InlineNotification | ClearInlineNotifications) => {
        if (notification instanceof InlineNotification) {
          return {
            type: this.getAlertType(notification.type),
            msgCode: notification.messageKey,
            message: notification.message,
            translationData: notification.messageParams,
            showRecoveryLink: notification.showLink,
            recoveryLink: notification.linkUrl,
            recoveryText: notification.linkText,
          } as AlertInfo;
        } else {
          return null;
        }
      }),
      tap(() => this.changeDetector.markForCheck()),
    );
  }

  private subscribeToRouterEvents() {
    this.subscriptions.add(
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationStart) {
          this.notification.clear();
        }
      }),
    );
  }

  private getAlertType(notificationType: NotificationType) {
    switch (notificationType) {
      case NotificationType.SUCCESS: {
        return 'success';
      }
      case NotificationType.ERROR: {
        return 'error';
      }
      case NotificationType.WARNING: {
        return 'warning';
      }
      case NotificationType.INFO: {
        return 'primary';
      }
    }
  }
}
