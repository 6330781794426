import { ChangeDetectionStrategy, Component } from '@angular/core';
import { PasswordComponent } from '@security/change-password';
import { DefaultLayoutComponent } from '@shared/layout/components/default-layout';
import { BreadcrumbConfiguration } from '@shared/layout/models';

@Component({
  template: `
    <ic-default-layout [breadcrumb]="breadcrumb">
      <ic-change-password></ic-change-password>
    </ic-default-layout>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [DefaultLayoutComponent, PasswordComponent],
})
export class ChangePasswordComponent {
  public readonly breadcrumb: BreadcrumbConfiguration = [{ label: 'CHANGE_PASSWORD' }];
}
