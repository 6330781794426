import { Injectable } from '@angular/core';
import { ChartData, ConsumptionUnit } from '../models';

@Injectable({ providedIn: 'root' })
export class ConsumptionChartDataService {
  public convertChartData(chartData: ChartData, goalUnit: ConsumptionUnit, convertFactor: number) {
    let noChanges = true;

    if (chartData.unit !== goalUnit) {
      chartData.dataPoints.forEach((dataPoint) => {
        dataPoint.value = dataPoint.value * convertFactor;
      });
      noChanges = false;
    }
    if (chartData.averageUnit !== goalUnit) {
      chartData.averageDataPoints.forEach((averageDataPoint) => {
        averageDataPoint.value = averageDataPoint.value * convertFactor;
      });
      noChanges = false;
    }
    if (noChanges) {
      return chartData;
    } else {
      return {
        dataPoints: chartData.dataPoints,
        unit: goalUnit,
        averageDataPoints: chartData.averageDataPoints,
        averageUnit: goalUnit,
        showAverage: chartData.showAverage,
      };
    }
  }
}
